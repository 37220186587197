var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.showAppBar)?_c('v-app-bar',{attrs:{"app":"","dense":""}},[_c('v-img',{staticClass:"mx-1",staticStyle:{"cursor":"pointer"},attrs:{"src":require('../assets/app-bar-logo.png'),"max-height":"32","max-width":"32","contain":""},on:{"click":_vm.goToRoot}}),_c('v-toolbar-title',{staticClass:"headline",staticStyle:{"cursor":"pointer"},on:{"click":_vm.goToRoot}},[_vm._v(" Crypto-Mation ")]),_c('v-spacer'),_c('v-toolbar-items',{staticClass:"hidden-sm-and-down"},[_c('v-btn',{attrs:{"text":"","ripple":false,"to":"/tradingbot"}},[_vm._v(" "+_vm._s(_vm.$t("appbar.tradingBot"))+" ")]),_c('v-btn',{attrs:{"text":"","ripple":false,"to":"/docs/registerbot"}},[_vm._v(" "+_vm._s(_vm.$t("appbar.documents"))+" ")]),(!_vm.isLogin)?_c('v-btn',{attrs:{"text":"","to":"/signin"}},[_vm._v(" "+_vm._s(_vm.$t("auth.signIn"))+" ")]):_vm._e(),_c('LanguageFlatBtn')],1),_c('v-menu',{staticClass:"hidden-md-and-up",scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-app-bar-nav-icon',_vm._g(_vm._b({staticClass:"hidden-md-and-up"},'v-app-bar-nav-icon',attrs,false),on))]}}],null,false,3578200497)},[_c('v-list',[_c('v-list-item',{attrs:{"to":"/tradingbot","link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("appbar.tradingBot")))])],1),_c('v-list-item',{attrs:{"to":"/docs/registerbot","link":""}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("appbar.documents")))])],1),(!_vm.isLogin)?_c('v-list-item',{attrs:{"to":"/signin"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("auth.signIn")))])],1):_vm._e(),_c('LanguageFlatItemList')],1)],1),(_vm.isLogin)?_c('v-menu',{attrs:{"bottom":"","min-width":"200px","rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","x-large":""}},on),[_c('v-avatar',{attrs:{"color":"","size":"40"}},[_c('span',{staticClass:"white--text text-h5"},[_vm._v(_vm._s(_vm.userData.name))])])],1)]}}],null,false,3963588551)},[_c('v-card',[_c('v-list-item-content',{staticClass:"justify-center"},[_c('div',{staticClass:"mx-auto text-center"},[_c('p',{staticClass:"text-caption mt-2"},[_vm._v(_vm._s(_vm.userData.email))]),_c('div',{staticClass:"text-caption"},[_vm._v(" Login Type: "+_vm._s(_vm.userData.sign_in_provider)+" "),(_vm.userData.verified && _vm.userData.sign_in_provider == 'Email')?_c('div',{staticClass:"green--text"},[_vm._v(" (Verified) ")]):_vm._e(),(
                !_vm.userData.verified && _vm.userData.sign_in_provider == 'Email'
              )?_c('div',{staticClass:"red--text"},[_vm._v(" (Not Verified) ")]):_vm._e()]),(!_vm.userData.verified && _vm.userData.sign_in_provider == 'Email')?_c('div',[_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.goToPage('/verifyemail')}}},[_vm._v(" Verify Email ")])],1):_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.goToPage('/subscriptions')}}},[_vm._v(" "+_vm._s(_vm.$t("appbar.MySubscriptions"))+" ")]),(_vm.userData.sign_in_provider == 'Email')?_c('div',[_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.goToPage('/changepassword')}}},[_vm._v(" "+_vm._s(_vm.$t("appbar.changePassword"))+" ")])],1):_vm._e(),_c('v-divider',{staticClass:"my-3"}),_c('v-btn',{attrs:{"depressed":"","rounded":"","text":""},on:{"click":function($event){$event.preventDefault();return _vm.signOut.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("appbar.SignOut"))+" ")])],1)])],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }